
import Vue from "vue"
import SharesReportTable from "@/components/shares/SharesReportTable.vue"
import LogoDisplayer from "@evercam/shared/components/LogoDisplayer"

import { mapStores } from "pinia"
import { useShareStore } from "@evercam/dashboard/stores/shares"

export default Vue.extend({
  name: "ShareRequestsTable",
  components: { SharesReportTable, LogoDisplayer },
  computed: {
    ...mapStores(useShareStore),
    headers(): Record<string, unknown>[] {
      return [
        {
          value: "email",
          text: this.$t("content.shares.headers.shared_with") as string,
          sortable: true,
          width: "33%",
        },
        {
          value: "sharerName",
          text: this.$t("content.shares.headers.shared_by") as string,
          sortable: true,
          width: "23%",
        },
        {
          value: "rights",
          text: this.$t("content.shares.headers.rights") as string,
          sortable: false,
        },
        {
          value: "action",
          text: this.$t("content.shares.headers.action") as string,
          sortable: false,
          width: "7%",
        },
      ]
    },
  },
})
