
import Vue from "vue"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
  RouteParams,
} from "@evercam/shared/types"
import VisibilityDialog from "@/components/shares/VisibilityDialog"
import CreateShareDialog from "@/components/shares/CreateShareDialog"
import { mapStores } from "pinia"
import { useShareStore } from "@evercam/dashboard/stores/shares"
import { useCameraStore } from "@evercam/dashboard/stores/camera"
import { camelizeKeys } from "humps"
import ShareRequestsTable from "@/components/shares/ShareRequestsTable.vue"
import SharesTable from "@/components/shares/SharesTable.vue"

export default Vue.extend({
  name: "Share",
  meta: {
    pageId: AnalyticsEventPageId.Sharing,
  },
  components: {
    ShareRequestsTable,
    SharesTable,
    VisibilityDialog,
    CreateShareDialog,
  },
  middleware: ["ensureCameraRoute"],
  async asyncData({ redirect, app }) {
    const cameraStore = useCameraStore()

    if (!app.$permissions.user.can.access.sharing()) {
      return redirect(cameraStore.cameraRoute)
    }
    const canViewShares = cameraStore.selectedCamera?.rights
      ?.split(",")
      .find((o) => ["share", "edit", "delete"].includes(o))

    if (!canViewShares) {
      return redirect(cameraStore.cameraRoute)
    }
  },
  head() {
    return {
      title: `${this.cameraStore.selectedCamera?.name || "Evercam"} | ${this.$t(
        "pages.sharing"
      )}`,
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useShareStore, useCameraStore),
    cameraDiscoverability() {
      if (!this.cameraStore.isCameraPublic) {
        return {
          icon: "fa-solid fa-lock",
          description:
            this.$t("content.shares.visibility_options.private.label") +
            ", " +
            this.$t("content.shares.visibility_options.this_camera_is"),
        }
      }

      if (!this.cameraStore.selectedCamera?.discoverable) {
        return {
          icon: "fas fa-link",
          description:
            this.$t(
              "content.shares.visibility_options.public_undiscoverable.label"
            ) +
            ", " +
            this.$t("content.shares.visibility_options.this_camera_is"),
        }
      }

      return {
        icon: "fas fa-globe",
        description:
          this.$t(
            "content.shares.visibility_options.public_discoverable.label"
          ) +
          ", " +
          this.$t("content.shares.visibility_options.this_camera_is"),
      }
    },
    cameraStatusText() {
      if (!this.cameraStore.isCameraPublic) {
        return "Private"
      }

      if (!this.cameraStore.selectedCamera?.discoverable) {
        return "Public, Not Discoverable"
      }

      return "Public"
    },
    cameraUrl() {
      return `${window.location.origin}${this.cameraStore.cameraRoute}`
    },
    routeCameraExid(): string {
      const camelizedKeys = camelizeKeys(this.$route.params) as RouteParams

      return camelizedKeys?.cameraExid
    },
  },
  watch: {
    "cameraStore.cameras"() {
      if (this.cameraStore.cameras?.length) {
        this.cameraStore.selectCamera(this.routeCameraExid)
      }
    },
    "shareStore.addUserDialog"(value) {
      this.$analytics.saveEvent(AnalyticsEvent.SharingToggleAddUserDialog, {
        visible: value,
      })
    },
  },
  async created() {
    await this.shareStore.fetchShareRequests(this.routeCameraExid)
    await this.shareStore.fetchShares(this.routeCameraExid)
    await this.shareStore.updateShareObject()
  },
  mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.PageView)
  },
  methods: {
    async openDeleteDialog() {
      this.$analytics.saveEvent(AnalyticsEvent.SharingToggleDeleteUserDialog, {
        visible: true,
      })
      if (
        await this.$confirmDialog.open({
          title: this.$t("title.delete_sharer"),
          message: this.$t("content.shares.delete_share"),
        })
      ) {
        this.shareStore.deleteShareOrShareRequest(this.routeCameraExid)
      }
      this.$analytics.saveEvent(AnalyticsEvent.SharingToggleDeleteUserDialog, {
        visible: false,
      })
    },
  },
})
