
import Vue from "vue"
import CopyToClipboardBtn from "@evercam/shared/components/CopyToClipboardBtn"
import { mapStores } from "pinia"
import { useShareStore } from "@evercam/dashboard/stores/shares"
import { useCameraStore } from "@evercam/dashboard/stores/camera"

import { AnalyticsEvent } from "@evercam/shared/types"
import SharesReportTable from "@/components/shares/SharesReportTable.vue"
import LogoDisplayer from "@evercam/shared/components/LogoDisplayer"
import { useAccountStore } from "@evercam/dashboard/stores/account"

export default Vue.extend({
  name: "ShareTable",
  components: {
    SharesReportTable,
    CopyToClipboardBtn,
    LogoDisplayer,
  },
  data() {
    return {
      userEmail: useAccountStore().email,
    }
  },
  computed: {
    ...mapStores(useShareStore, useCameraStore),
    headers(): Record<string, unknown>[] {
      return [
        {
          value: "fullname",
          text: this.$t("content.shares.headers.shared_with") as string,
          sortable: true,
          width: "33%",
        },
        {
          value: "sharerName",
          text: this.$t("content.shares.headers.shared_by") as string,
          sortable: true,
          width: "25%",
        },
        {
          value: "rights",
          text: this.$t("content.shares.headers.rights") as string,
          sortable: false,
        },
        {
          value: "lastSeenAt",
          text: this.$t("content.shares.headers.last_seen") as string,
          sortable: true,
          width: "10%",
        },
        {
          value: "action",
          text: this.$t("content.shares.headers.action") as string,
          sortable: false,
          width: "5%",
        },
      ]
    },
    cameraUrl(): string {
      return `${window.location.origin}${this.cameraStore.cameraRoute}`
    },
    isPublicCamera(): boolean {
      return this.cameraStore.isCameraPublic
    },
  },
  methods: {
    copyCameraUrl() {
      this.$analytics.saveEvent(AnalyticsEvent.SharingCopyLink)
    },
    fromNow(date: string) {
      return date ? this.$moment(date).fromNow() : "-"
    },
    formatDate(date: string) {
      return date && this.$moment(date).format("LLLL")
    },
  },
})
