
import Vue, { PropType } from "vue"
import { camelizeKeys } from "humps"

import TransferOwnerDialog from "@/components/shares/TransferOwnerDialog"

import { mapStores } from "pinia"
import { useShareStore } from "@evercam/dashboard/stores/shares"
import { useCameraStore } from "@evercam/dashboard/stores/camera"

import {
  AnalyticsEvent,
  ProjectShare,
  RouteParams,
  SharePermission,
  ShareType,
} from "@evercam/shared/types"
import { debounce } from "@evercam/shared/utils"

export default Vue.extend({
  name: "SharesReportTable",
  components: { TransferOwnerDialog },
  props: {
    title: {
      type: String,
      required: true,
    },
    headers: {
      type: Array as PropType<Record<string, unknown>[]>,
      required: true,
    },
    items: {
      type: Array as PropType<ProjectShare[]>,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    sort: {
      type: String,
      default: "email",
    },
    itemsPerPageOptions: {
      type: Array as PropType<number[]>,
      default: () => [10, 20, 50],
    },
  },
  data() {
    return {
      searchQuery: "",
      sortBy: this.sort,
      sortDesc: true,
    }
  },
  computed: {
    ShareType() {
      return ShareType
    },
    ...mapStores(useShareStore, useCameraStore),
    rights(): { text: string; value: SharePermission; disabled?: boolean }[] {
      return [
        {
          text: this.$t(
            "content.shares.create_form.rights_labels.read_only"
          ) as string,
          value: SharePermission.Minimum,
        },
        {
          text: this.$t(
            "content.shares.create_form.rights_labels.read_only_share"
          ) as string,
          value: SharePermission.Share,
        },
        {
          text: this.$t(
            "content.shares.create_form.rights_labels.full"
          ) as string,
          value: SharePermission.Full,
          disabled:
            this.cameraStore.selectedCamera?.rights.indexOf("edit") === -1,
        },
      ]
    },
    routeCameraExid(): string {
      const camelizedKeys = camelizeKeys(this.$route.params) as RouteParams

      return camelizedKeys?.cameraExid
    },
    sortObject(): { sortBy: string; sortDesc: boolean } {
      return { sortBy: this.sortBy, sortDesc: this.sortDesc }
    },
    filteredShares(): unknown {
      return this.items.filter(this.filterShare)
    },
    sharesContainerClasses(): Record<string, boolean> {
      return {
        "e-flex-col e-items-start e-gap-2 e-justify-start":
          this.$vuetify.breakpoint.smAndDown,
        "e-justify-between e-items-center": !this.$vuetify.breakpoint.smAndDown,
      }
    },
    actionsClasses(): Record<string, boolean> {
      return {
        "e-flex-col e-items-start e-gap-2 e-justify-start e-w-full":
          this.$vuetify.breakpoint.smAndDown,
        "e-h-[40px] e-justify-end e-gap-2 e-items-center":
          !this.$vuetify.breakpoint.smAndDown,
      }
    },
    inputSearchClasses(): Record<string, boolean> {
      return {
        "search__field e-ring-1 e-w-60 e-ring-inset e-relative e-block e-pr-6 e-flex-1 e-w-full e-h-full disabled:e-cursor-not-allowed disabled:e-opacity-75 focus:e-outline-none e-border-0 e-text-sm e-placeholder-gray-400 e-px-2.5 e-pr-8 e-py-2 e-gap-x-1.5 e-rounded hover:e-ring-gray-400":
          true,
        "e-bg-gray-900 e-text-white e-ring-gray-700 focus:e-ring-2 focus:e-ring-blue-500 e-placeholder-gray-500":
          this.$vuetify.theme.dark,
        "e-bg-white e-text-[#000057] e-ring-gray-300 focus:e-ring-2 focus:e-ring-blue-500 e-placeholder-gray-400":
          !this.$vuetify.theme.dark,
      }
    },
    sharesContainerStyle(): Record<string, string> {
      return {
        width: this.$vuetify.breakpoint.smAndDown ? "100%" : "256px",
      }
    },
    debouncedSharesSearch: {
      get() {
        return this.searchQuery
      },
      set: debounce(function (newValue) {
        this.searchQuery = newValue
      }, 100),
    },
  },
  watch: {
    sortObject({ sortBy, sortDesc }) {
      if (sortBy && sortDesc) {
        this.$analytics.saveEvent(AnalyticsEvent.SharingSortBy, {
          sortBy,
          sortDesc,
        })
      }
    },
  },
  methods: {
    isRestricted(shareeEmail: string) {
      return this.$permissions.user.is.restricted(shareeEmail)
    },
    filterShare(share: ProjectShare) {
      let isFullNameMatch = false
      if (share.fullname) {
        isFullNameMatch = share.fullname
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase())
      }

      return (
        isFullNameMatch ||
        share.email.toLowerCase().includes(this.searchQuery.toLowerCase())
      )
    },
    clearSearch() {
      this.searchQuery = ""
    },
  },
})
