
import RecipientsSelector from "@/components/RecipientsSelector"
import { SharePermission } from "@evercam/shared/types/shares"
import Textarea from "@evercam/shared/components/Textarea"
import Vue from "vue"
import { mapStores } from "pinia"
import { useShareStore } from "@/stores/shares"
import { useProjectStore } from "@/stores/project"
import { useCameraStore } from "@/stores/camera"
import { camelizeKeys } from "humps"
import { AnalyticsEvent } from "@evercam/shared/types"

export default Vue.extend({
  components: { Textarea, RecipientsSelector },
  data() {
    return {
      shareAllCameras: false,
    }
  },
  computed: {
    ...mapStores(useShareStore, useProjectStore, useCameraStore),
    rights() {
      return [
        {
          text: this.$t("content.shares.create_form.rights_labels.read_only"),
          value: SharePermission.Minimum,
        },
        {
          text: this.$t(
            "content.shares.create_form.rights_labels.read_only_share"
          ),
          value: SharePermission.Share,
        },
        {
          text: this.$t("content.shares.create_form.rights_labels.full"),
          value: SharePermission.Full,
          disabled:
            this.cameraStore.selectedCamera?.rights.indexOf("edit") === -1,
        },
      ]
    },
    totalProjectCameras() {
      return this.projectStore.selectedProjectCameras.length || 0
    },
    routeCameraExid() {
      return camelizeKeys(this.$route.params)?.cameraExid
    },
  },
  methods: {
    async onSave() {
      this.$analytics.saveEvent(AnalyticsEvent.SharingAddUser)
      const generateRightList = function (permissions) {
        let rights = ["list", "view", "snapshot"]

        if (permissions === SharePermission.Share) {
          rights = [...rights, "share"]
        } else if (permissions === SharePermission.Full) {
          rights = [...rights, "share", "edit"]
        }

        return rights.join(",")
      }
      if (this.shareAllCameras) {
        const cameraExids = this.projectStore.selectedProjectCameras.map(
          (camera) => camera.id
        )
        const payload = {
          ...this.shareStore.createdShare,
          email: this.shareStore.createdShare.email,
          rights: generateRightList(this.shareStore.createdShare.permission),
          cameraExids,
        }

        await this.shareStore.handleShareAllCameras(payload)
      } else {
        await this.shareStore.createShare(this.routeCameraExid)
      }
      this.shareStore.addUserDialog = false
      this.$refs.formObserver?.reset()
    },
    cancelShareDialog() {
      this.shareStore.addUserDialog = false
      this.shareStore.createdShare = { ...this.shareStore.defaultCreatedShare }
    },
  },
})
